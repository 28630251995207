'use client';

import { useEffect, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';

//* HOC's
import { withLanguageContext } from '@/context';

//* Helper's
import { config } from '@/helpers';

//* Components
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';
import LottieAnimation from '@/components/global/LottieAnimation';

//* Style
import Custom404Style from './Custom404Style';

//* Lottie
import lottie404 from '@/lottieFiles/404_v1_lottie.json';
import lottie404_1 from '@/lottieFiles/404_v1_lottie_2.json';

const NotFound = ({ selectedLang, translate }) => {
	//! Next Navigation
	const router = useRouter();
	const pathname = usePathname();

	//! States
	const [showSecondLottie, setShowSecondLottie] = useState(false);

	//! Check
	useEffect(() => {
		if (!(pathname.replace(selectedLang + '/', '') == '/not-found/')) {
			router.push(`/${selectedLang}/not-found/`) || router.push(`/not-found/`);
		}
	}, [router, pathname, selectedLang]);

	//! Show / Hide Lottie
	const handleFirstLottieEnd = () => {
		setShowSecondLottie(true);
	};

	return (
		<Custom404Style>
			<Container
				row
				className={'error-container'}>
				<div className={`col-6 col-t-11 col-m-12 left-wrapper`}>
					<Text
						tag={`h1`}
						className={`blue-1000-color font-montserrat-medium title uppercase`}
						text={'404title'}
					/>

					<Text
						className={'p p5 blue-1000-color font-montserrat-regular description'}
						text={'404description'}
					/>

					<Button
						btnType={'oval'}
						text={translate('goToHomepage')}
						url={config.routes.home.path}
					/>
				</div>

				<div className={`col-1 col-t-2 col-m-12`}></div>

				<div className={`col-4 col-t-8 col-m-12 lottie-section`}>
					{!showSecondLottie && (
						<LottieAnimation
							loop={false}
							autoplay
							animData={lottie404}
							parentMethod={handleFirstLottieEnd}
						/>
					)}

					{showSecondLottie && (
						<LottieAnimation
							loop
							autoplay
							animData={lottie404_1}
						/>
					)}
				</div>

				<div className={`col-1 col-t-2 col-m-12`}></div>
			</Container>
		</Custom404Style>
	);
};

export default withLanguageContext(NotFound, ['selectedLang', 'translate']);
