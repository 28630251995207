import styled from 'styled-components';

const Custom404Style = styled.section`
	--containerPaddingT: var(--sp15x);
	--marginT: var(--sp4x);
	--marginB: var(--sp7x);
	--lottiePaddingL: var(--sp5x);
	--lottiePaddingR: var(--sp4x);
	--lottieMarginT: 0;

	.error-container {
		padding-top: var(--containerPaddingT);
	}

	.description {
		margin: var(--marginT) 0 var(--marginB);
	}

	.left-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.lottie-wrap {
		margin-top: var(--lottieMarginT);
		padding-left: var(--lottiePaddingL);
		padding-right: var(--lottiePaddingR);
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--containerPaddingT: var(--sp10x);
		--marginT: var(--sp3x);
		--marginB: var(--sp5x);
		--lottiePaddingL: var(--sp3x);
		--lottiePaddingR: var(--sp3x);
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--containerPaddingT: var(--sp10x);
		--marginT: var(--sp3x);
		--marginB: var(--sp5x);
		--lottiePaddingL: var(--sp3x);
		--lottiePaddingR: var(--sp3x);
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--containerPaddingT: var(--sp8x);
		--marginT: var(--sp2x);
		--marginB: var(--sp4x);
		--lottiePaddingL: var(--sp3x);
		--lottiePaddingR: var(--sp3x);
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--containerPaddingT: var(--sp8x);
		--marginT: var(--sp2x);
		--marginB: var(--sp4x);
		--lottiePaddingL: var(--sp2x);
		--lottiePaddingR: var(--sp2x);
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--containerPaddingT: var(--sp6x);
		--marginT: var(--sp2x);
		--marginB: var(--sp3x);
		--lottieMarginT: var(--sp10x);
		--lottiePaddingL: var(--sp3x);
		--lottiePaddingR: var(--sp3x);
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--containerPaddingT: var(--sp5x);
		--marginT: var(--sp2x);
		--marginB: var(--sp3x);
		--lottieMarginT: var(--sp5x);
		--lottiePaddingL: var(--sp2x);
		--lottiePaddingR: var(--sp2x);
	}
`;

export default Custom404Style;
